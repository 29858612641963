import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/should-we-declare-react-functional-components-with-a-const-or-a-function",
  "date": "2021-10-16",
  "title": "Should we declare React functional components with a const or a function?",
  "author": "admin",
  "tags": ["development", "javascript", "typesript", "react"],
  "featuredImage": "feature.jpg",
  "excerpt": "If you have been writing React functional components for a while now, you may have encountered two ways of declaring a component - an arrow function or a function declaration."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`There are two ways of declaring a component.`}</p>
    <ol>
      <li parentName="ol">{`Using an arrow function`}</li>
      <li parentName="ol">{`Using function declaration`}</li>
    </ol>
    <p>{`If you look at the React documentation, you can see that the components declarations are done with the function syntax:`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/components-and-props.html"
      }}>{`React documentation: Components and Props`}</a></p>
    <p>{`Using an arrow function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const App = () => {
    return(
      ..
    );
}
`}</code></pre>
    <p>{`And using function declaration :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`function App() {
    return(
      ..
    );
}
`}</code></pre>
    <p>{`If your component only returns JSX, then the first option (arrow function) seems to be shorter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const App = () => <>Hello!</>;
`}</code></pre>
    <p>{`Versus:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`function App() {
    return <>Hello!</>;
}
`}</code></pre>
    <h2>{`Exporting the default component`}</h2>
    <p>{`But if when are exporting your component as default, this is no longer the case:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const App = () => {};

export default MyComponent;
`}</code></pre>
    <p>{`Versus:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export default function App() {
    return <>Hello!</>;
}
`}</code></pre>
    <p>{`You could also omit the name and directly export the function expression:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export default function() {
    return <>Hello!</>;
}
`}</code></pre>
    <h2>{`Hoisting`}</h2>
    <p>{`This is where function syntax shines! Let's look at an example starting with the arrow function syntax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const FirstComponent = () => {}

const SecondComponent = () => {}

const App = () => (
    <>
      <FirstComponent />
      <SecondComponent />
    </>
);

export default App;
`}</code></pre>
    <p>{`This syntax is valid. But things get hairy if you declare your other components after your main component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const App = () => (
    <>
      <FirstComponent />
      <SecondComponent />
    </>
);

const FirstComponent = () => <>First</>

const SecondComponent = () => <>Second</>

export default App;
`}</code></pre>
    <p>{`Your linter will throw an error because the components are used before they have been declared.`}</p>
    <p>{`But if we declared your components using function syntax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`function App() { 
  return (
    <>
      <FirstComponent />
      <SecondComponent />
    </>
  );
}

const FirstComponent = () => { return <>First</> }

const SecondComponent = () => { return <>Second</> }

export default App;
`}</code></pre>
    <h2>{`Generics`}</h2>
    <p>{`If you are using TypeScript and using generic types in your component, you have to use the function syntax.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`interface Item {
  name: string;
}

interface ListProps<T> {
  items: T[];
  onClick: (item: T) => void;
}

function List<T = unknown>(props: ListProps<T>) {
  return <>TODO</>
}

function App() {
  const items: Item[] = [{ name: 'Sean'}];
  const onClick = (item: Item) => console.log(item); 
  return <List<Item> items={items} onClick={onClick} />
} 
`}</code></pre>
    <p>{`At the end of the day, you don't have to pick one or the other. But having these differences in mind will help you to decide which option to use when you write your component declarations.`}</p>
    <h2>{`Summary`}</h2>
    <ol>
      <li parentName="ol">{`Function declarations are actually fewer characters than const arrow function statements:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// 16 characters  
function App() {

}
// 20 characters
const App = () => {
}
`}</code></pre>
    <ol>
      <li parentName="ol">{`If you are writing multiple components in one file, it's better to use function syntax as hoisting may come into effect`}</li>
      <li parentName="ol">{`If you want to use generics, you will have to use function syntax`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      